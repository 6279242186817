import Vue from "vue";
import Router from "vue-router";
import index from "./views/index.vue";
import {TOKEN} from "@/config/http-json";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "Login",
      component: () => import('./views/Login.vue'),
      meta: {
        keepAlive: false
      }
    },
    {
      path: "/",
      name: "index",
      component: index,
      meta: {
        keepAlive: false,
        auth: true,
      }
    },
    {
      path: "/stores",
      name: "stores",
      component: () => import('./views/StoreList.vue'),
      meta: {
        keepAlive: false,
        auth: true,
      }
    },
    {
      path: "/storeEdit",
      name: "storeEdit",
      component: () => import('./views/StoreEdit.vue'),
      meta: {
        keepAlive: false,
        auth: true,
      }
    },

    {
      path: "*",
      redirect: {
        name: "/"
      }
    }
    // {
    //   path: "/about",
    //   name: "about",
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import(/* webpackChunkName: "about" */ "./views/About.vue"),
    // },
  ],
});

router.beforeEach((to, from, next) => {

  let token = window.localStorage.getItem(TOKEN);
  if (token) {
    // Store.commit("setLoginInfo", userInfo);
    if (to.fullPath === "/login") {
      //看看上次访问的是Connect 还是蓝牙
      return next({name: 'index'})
    }

    next();
  } else {
    if (to.meta.auth) {
      next({
        path: "/login",
        //跳转时传递参数到登录页面，以便登录后可以跳转到对应页面
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      //如果不需要登录鉴权，则直接跳转到对应页面
      next();
    }
  }


})

export default router