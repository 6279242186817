/*
 * @Author: Code-HHX
 * @Date: 2022-03-24 10:35:21
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-03-24 14:32:21
 * @Description: api 统一出口
 */

import {requestGet, requestGetForm, requestPost, requestPostForm} from "../config/http-json"

const storeApi = {
  async addStore(params) {
    const resp = await requestPost('/supplier/brandShop/saveOrUpdateShop', params);
    return resp.data
  },
  async modifyStore(params) {
    const resp = await requestPost('/supplier/brandShop/saveOrUpdateShop', params);
    return resp.data
  },
  async storeList(params) {
    const resp = await requestGet('/supplier/brandShop/selectShopList', params);
    return resp
  },
  async deleteStore(id) {
    const resp = await requestPostForm('/supplier/brandShop/deleteById', {id:id});
    return resp
  },
  async views() {
    const resp = await requestGet('/supplier/brandShop/selectViewCount');
    return resp.data
  },
  async brands() {
    const resp = await requestGet('/supplier/brandShop/selectAllBrand');
    return resp.data
  },
};

export default storeApi;
