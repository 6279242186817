<template>
  <div>
    <div name="google" ref="googlemap" class="map">
    </div>


    <div class="search-box" ref="searchBox" style="visibility:hidden">
      <input placeholder="Search" ref="searchInput" class="search-input">
    </div>
  </div>
</template>

<script>
import googleMapLoader, {positions} from "@/map/googlemap-loader";
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import StoreInfo from "@/components/StoreInfo.vue";

const coordtransform = require('coordtransform');
export default {
  name: "GoogleMap",
  components: {StoreInfo},
  props: {
    lat: {
      type: Number | String,
      default: 0
    },
    lng: {
      type: Number | String,
      default: 0
    }
  },
  data() {
    return {
      map: null,
      showInfo: false,
      infoViewContent: {
        email: '',
        storeName: '',
        addressName: '',
        phone: '',
      },
      selectMarkerPosition: null,
      customMarker: null,
      pressTimer: null,
      currentSelectAddress: null,
      geocoder: null
    }
  },
  mounted() {
    this.$nextTick(async () => {
      let position = {lat: 39.0732487, lng: -114.9321582}; //39.0732487,-114.9321582
      let zoom = 3
      const edit = this.lat && this.lng
      if (edit) {
        position = {lat: parseFloat(this.lat), lng: parseFloat(this.lng)}; //39.0732487,-114.9321582
        zoom = 17
      }
      // The location of Uluru
      // const position = {lat: 22.5759686, lng: 113.9194062};

      // Request needed libraries.
      //@ts-ignore
      const {Map} = await googleMapLoader.importLibrary("maps");
      const {Marker} = await googleMapLoader.importLibrary("marker");
      const {Autocomplete} = await googleMapLoader.importLibrary("places");
      const {Geocoder} = await googleMapLoader.importLibrary("geocoding");

      if (this.geocoder == null) {
        this.geocoder = new Geocoder()
      }
      // The map, centered at Uluru
      const map = new Map(this.$refs.googlemap, {
        zoom: zoom,
        center: position,

        mapTypeControl: false,
        streetViewControl: false,
      });


      google.maps.event.addListener(map, 'mouseup', (e) => {
        clearTimeout(this.pressTimer);
      });
      google.maps.event.addListener(map, 'mousedown', (e) => {
        clearTimeout(this.pressTimer);
        this.pressTimer = setTimeout(() => {

          const position = {lat: e.latLng.lat(), lng: e.latLng.lng()}
          this.handleLongPress(position, map, Marker, this.geocoder);
        }, 1000); // 设置长按的时间阈值，单位为毫秒
      });
      //隐藏旁边的poi 交通
      // const styles = {
      //   default: [],
      //   hide: [
      //     {
      //       featureType: "poi",
      //       stylers: [{visibility: "off"}],
      //     },
      //     {
      //       featureType: "transit",
      //       elementType: "labels.icon",
      //       stylers: [{visibility: "off"}],
      //     },
      //   ],
      // };
      // map.setOptions({styles: styles["hide"]});

      // this.addPantoCurrentPosition(map, Marker)

      // const infoWindow = new google.maps.InfoWindow({
      //   content: "",
      //   disableAutoPan: true,
      //   maxWidth: '70%',
      // });

      // const markers = this.locations.map((item, index) => {
      //
      //   // const gcj02Coords = coordtransform.wgs84togcj02(item.lng, item.lat);
      //   const marker = new Marker({
      //     position: {lat: parseFloat(item.lat), lng: parseFloat(item.lng)}
      //   })
      //
      //   marker.addListener('click', () => {
      //     this.showInfo = true
      //     this.selectMarkerPosition = marker.getPosition()
      //     this.infoViewContent = {
      //       email: item.email,
      //       storeName: item.name,
      //       addressName: item.street,
      //       phone: item.phone,
      //       website: item.website,
      //     }
      //     this.$nextTick(() => {
      //       infoWindow.setContent(this.$refs.storeinfo.$el);
      //       infoWindow.open(map, marker);
      //
      //
      //       if (map.getZoom() < 16) {
      //         map.setZoom(16);
      //       }
      //       map.setCenter(marker.getPosition());
      //       map.panBy(0, -100)
      //     })
      //   })
      //   return marker
      // })
      //
      // new MarkerClusterer({map, markers})

      //自动补全搜索
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.$refs.searchBox);
      const autocomplete = new Autocomplete(this.$refs.searchInput, {
        fields: ["formatted_address", "geometry", "name"],
        strictBounds: false,
      });
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }

        const position = place.geometry.location
        this.handleLongPress(position, map, Marker, this.geocoder)
        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17);
        }
      })


      //
      if (edit) {
        this.handleLongPress(position, map, Marker, this.geocoder)
      }

      this.map = map;
    })
  },
  methods: {
    async handleLongPress(position, map, Marker, geocoder) {
      if (this.customMarker === null) {
        this.customMarker = new Marker({
          map: map,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: 'blue',
            fillOpacity: 1,
            strokeColor: 'white',
            strokeWeight: 2,
            scale: 8
          }
        });
      }

      this.currentSelectAddress = await geocoder.geocode({location: position})
      this.$emit('onAddressGeoCode', {address: this.currentSelectAddress.results})
      this.customMarker.setPosition(position)

      map.setCenter(position);
      map.setZoom(17);
    },
    addPantoCurrentPosition(map, Marker) {
      const locationButton = document.createElement("button");
      locationButton.textContent = "Pan to Current Location";
      locationButton.classList.add("custom-map-control-button");
      map.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);
      locationButton.addEventListener("click", () => {
        // Try HTML5 geolocation.
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
              (position) => {
                console.log(`${position}`)
                const pos = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                };

                const gcj02Coords = coordtransform.wgs84togcj02(pos.lng, pos.lat);
                console.log(gcj02Coords)
                const marker = new Marker({
                  map,
                  position: {lat: gcj02Coords[1], lng: gcj02Coords[0]}
                })
                map.setZoom(10)
                map.setCenter(pos);
              },
              () => {
                console.log(`error callback`)
              }
          );
        } else {
          // Browser doesn't support Geolocation
          console.log(`error callback`)
        }
      });
    },
    onZoomClick() {
      this.map.setZoom(this.map.getZoom() + 1)
    }
  }
}
</script>


<style scoped lang="less">
.map {
  border-radius: 20px;
  width: 100%;
  height: 400px;
}

.search-box {
  margin: 10px 0 0 20px;
  width: 60%;

  .search-input {
    border-radius: 10px;
    width: 100%;
    height: 30px;
    border-color: transparent;
  }
}
</style>