<template>
  <div class="info-window-view">
    <div v-if="storeName" class="store-name"> {{ storeName }}</div>
    <div v-if="addressName" class="address-name">{{ addressName }}</div>
    <div v-if="email" class="email">{{ email }}</div>
    <div v-if="phone" class="phone">{{ phone }}</div>
    <div class="btn-item">
      <button  class="btn" @click="onWebsiteClick">Website</button>
      <button  class="btn" style="margin-left:10px;" @click="onZoomClick">Zoom</button>
    </div>
  </div>
</template>
<script>
export default {
  name: "StoreInfo",
  props: {
    storeName: {
      type: String,
      default: ''
    },
    addressName: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    phone: {
      type: String,
      default: ''
    },
    website: {
      type: String,
      default: ''
    },
    map: {
      type:Object,
      default: ()=> {
        return {}
      }
    },
    markerPosition: {
      type:Object,
      default: ()=> {
        return {}
      }
    }
  },
  methods: {
    onWebsiteClick() {
      window.open(this.website)
    },
    onZoomClick() {
      this.map.setCenter(this.markerPosition)
      this.map.setZoom(this.map.getZoom() + 1)
      this.map.panBy(0, -80)
    }
  }
}
</script>


<style scoped lang="less">
.info-window-view {
  display: flex;
  flex-direction: column;
  padding: 4px;
  font-size:12px;
  font-weight: bold;

  .store-name {
    color: #39a9ed;
    font-size: 18px;
  }

  .address-name {
    margin-top: 4px;
    color: #000000;
    font-size: 14px;
  }


  .email {
    margin-top: 4px;
    color: #39a9ed;
  }


  .phone {
    margin-top: 4px;
    color: #39a9ed;
  }

  .btn-item {
    margin-top:10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .btn {
      flex:1;
      padding:8px 8px;
      border: none;
      color: white;
      border-radius: 4px;
      background: #39a9ed;
    }
  }

}

</style>