<template>
  <div class="store-manage-wrap">
    <div class="cover-wrap">
      <van-image :src="require('@/assets/logo/bg-top-cover.png')" fit="contain"/>
      <div class="store-info-wrap">
        <div class="store-info">
          <div class="name">{{ getUserName }}</div>
          <div class="views">
            <van-image class="icon" :src="require('@/assets/logo/views.png')"/>
            Views: <span v-if="views !== null">{{ views }}</span> <span v-else><van-loading
              size="12"/></span>
          </div>
        </div>

        <van-image class="exit-logout" @click="onLogout"
                   :src="require('@/assets/logo/exit_logout.png')"/>
      </div>
    </div>

    <div class="address-manage-wrap">
      <div class="title-wrap">
        <div style="font-weight: bold">Store details</div>
        <div @click="onStoreListClick">Store list</div>
      </div>

      <div class="add-address-wrap">
        <van-form @submit="onSaveStore" @failed="onFailed">
          <div class="form-item">
            <span>Store Name</span>
            <van-field v-model="store.name" placeholder="Store name"
                       :rules="[{ required: true, message: 'Please Input store name' }]"/>
          </div>
          <div class="form-item">
            <van-cell title="Address" is-link :value="store.address" @click="onAddressSelect"/>
            <GoogleMap style="margin-top:10px" @onAddressGeoCode="onAddressGeoCode"/>
          </div>
          <div class="form-item">
            <span>Official website</span>
            <van-field v-model="store.website" placeholder="Official website url"
                       :rules="[{ required: true, message: 'Please input website url' }]"/>
          </div>
          <div class="form-item">
            <span>Telephone</span>
            <van-field v-model="store.phone" placeholder="Telephone"
                       :rules="[{ required: true, message: 'Please input telephone' }]"/>
          </div>
          <div class="form-item">
            <span>Email</span>
            <van-field v-model="store.email" placeholder="Email"
                       :rules="[{ required: true, message: 'Please input email' }]"/>
          </div>
          <div class="form-item" v-if="brands">
            <div>Brand</div>

            <van-checkbox-group style="margin-top:10px" v-model="store.brandId" direction="horizontal">
              <van-checkbox v-for="(item,index) in brands" :key="item.id" :name="item.id">{{item.brandName}}</van-checkbox>
            </van-checkbox-group>
          </div>

          <van-button native-type="submit" class="save-button">Save</van-button>
        </van-form>
      </div>
    </div>

    <div class="save-button-warp">
    </div>

    <van-popup v-model="showPicker" round position="bottom">

      <van-picker
          title="Select Address"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
      />
    </van-popup>

  </div>
</template>

<script>
import GoogleMap from "@/components/GoogleMap.vue";
import {mapGetters} from "vuex";
import storeApi from "@/api/store";

export default {
  name: 'index',
  components: {GoogleMap},
  data() {
    return {
      store: {
        name: '',
        address: '',
        website: '',
        phone: '',
        email: '',
        lat: 0,
        lng: 0,
        brandId:[]
      },
      rules: {
        idPattern: (val) => {
          return val.trim().length > 0
        }
      },
      addresses: [],
      columns: [],
      showPicker: false,
      views: null,
      brands: []

    }
  },
  computed: {
    ...mapGetters(["getUserName"])
  },
  async mounted() {
    const views = await storeApi.views();
    this.views = views
    const allBrand = await storeApi.brands();
    this.brands = allBrand
  },
  methods: {
    onStoreListClick() {
      this.$router.push('/stores')
    },
    onAddressSelect() {
      if (this.store.address === '') {
        this.$toast('Please long press map or search from map to get address')
        return
      }
      this.showPicker = true

    },
    onAddressGeoCode(addresses) {
      this.addresses = addresses
      const defaultAddress = addresses.address && addresses.address[0]
      if (defaultAddress) {
        this.store.address = defaultAddress.formatted_address || ''
        const latLng = defaultAddress.geometry.location
        this.store.lat = latLng.lat()
        this.store.lng = latLng.lng()
      }

      this.showPicker = false
      this.columns = addresses.address.map(item => item.formatted_address)

    },
    onConfirm(value, index) {
      this.store.address = this.columns[index]
      const latLng = this.addresses.address[index].geometry.location
      this.store.lat = latLng.lat()
      this.store.lng = latLng.lng()
      this.showPicker = false
    },
    onCancel() {
      this.showPicker = false
    },
    onLogout() {
      this.$store.dispatch("tokenExpired")
    },
    onFailed(e) {
      console.log(e);
      const errorMsg = e.errors[0].message
      errorMsg && this.$toast(errorMsg)
    },
    async onSaveStore() {
      const {name, address, website, email, phone} = this.store;
      if (!address) {
        this.$toast('Please select precise address')
        return
      }

      const params = Object.assign({}, this.store);
      params.street = params.address
      params.brandId = params.brandId.join(",")
      delete params.address
      try {
        await storeApi.addStore(params)
        this.$toast('Add success!')
        this.store = {
          name: '',
          address: '',
          website: '',
          phone: '',
          email: '',
          brandId: []
        }
      } catch (e) {

      }

    }
  },
}
</script>

<style lang="less" scoped>

.store-manage-wrap {
  color: white;
  display: flex;
  flex-direction: column;

  .cover-wrap {
    position: relative;

    .store-info-wrap {
      position: absolute;
      top: 0;
      right: 30px;
      bottom: 25px;
      left: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .store-info {
        display: flex;
        flex-direction: column;

        .name {
          font-weight: bold;
          font-size: 24px;
        }

        .views {
          font-size: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;

          .icon {
            width: 20px;
            heigiht: 20px;
            margin-right: 10px;
          }
        }


      }

      .exit-logout {
        width: 30px;
        height: 30px;
        padding: 10px 0 10px 10px;
      }
    }
  }

  .address-manage-wrap {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    margin-top: -40px;
    border-radius: 30px;
    background: white;
    z-index: 1;
    overflow: hidden;
    padding: 20px;
    min-height: calc(100vh - 146px);

    .title-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .add-address-wrap {
      display: flex;
      flex-direction: column;
      background: #F8F8F8;
      border-radius: 20px;
      padding: 10px;


      .form-item {
        margin-top: 20px;

        .address-select {
          margin-top: 10px;
          font-weight: bold;
          color: #0075FF;
          padding: 10px;
          font-size: 12px;
          background: #BBBBBB;
          border-radius: 10px;

        }
      }
    }
  }
}


::v-deep .van-cell {
  margin-top: 8px;
  background: white;
  border-radius: 10px;
}

.save-button-warp {
  z-index: 2;
  position: fixed;
  bottom: 30px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.save-button {
  margin-top: 20px;
  border-radius: 8px;
  width: 100%;
  color: white;
  background: #0075FF;
}

</style>
