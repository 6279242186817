/*
 * @Author: Code-HHX
 * @Date: 2021-12-10 11:15:50
 * @LastEditors: Code-HHX
 * @LastEditTime: 2021-12-31 11:00:43
 * @Description:
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from "./api/auth";
import * as utils from "./util/utils";
import Meta from 'vue-meta'
import "amfe-flexible";
import Waves from "vue-waves-effect";
import "vue-waves-effect/dist/vueWavesEffect.css";
// import echarts from "./config/echarts-load";
import VueClipboard from "vue-clipboard2";

import {
	Swipe,
	SwipeItem,
	Lazyload,
	Col,
	Row,
	Popup,
	Toast,
	Image,
	CellGroup,
	Field,
	Button, Picker, Cell, Form, Loading, List, PullRefresh, Icon, Checkbox, CheckboxGroup
} from 'vant'

Vue.config.productionTip = false
//语言包
import VueI18n from "vue-i18n";
import {Loader} from "@googlemaps/js-api-loader";
Vue.use(VueI18n);

const navLang = navigator.language || navigator.userLanguage || "en-US";
// console.log(navLang);
// let localLang = navLang || false;
// let lang = "zh" || "enUS";
//
// if (localLang == "zh-CN") {
// 	localLang = "en-US";
// 	lang = "en-US";
// 	//localLang = "th-TH";
// 	//lang = "th-TH";
// }
// localStorage.setItem("lang", "zh-CN");

const i18n = new VueI18n({
	locale: navLang, // 语言标识
	messages: {
		en: require("./config/languages/en-US.json"),
		enUS: require("./config/languages/en-US.json"),
		th: require("./config/languages/th-TH.json"),
		zh: require("./config/languages/zh-CN.json"),
		"th-TH": require("./config/languages/th-TH.json"),
		"en-US": require("./config/languages/en-US.json"),
		"zh-CN": require("./config/languages/zh-CN.json"),
	}
});

Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Lazyload)
Vue.use(Col)
Vue.use(Row)
Vue.use(Popup)
Vue.use(Toast)
Vue.use(Meta)
Vue.use(Image)
Vue.use(Button)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Icon)
Vue.use(Form)
Vue.use(Loading)
Vue.use(Field)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Waves);
Vue.use(Picker);
Vue.use(VueClipboard);




//全局暴露api接口
Vue.prototype.$api = api;
Vue.prototype.$utils = utils;
// Vue.prototype.$echarts = echarts;


new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#app')
