import {Loader} from "@googlemaps/js-api-loader";

const googleMapLoader = new Loader({
  apiKey: "AIzaSyBuB-gjkPCkEf9C5VlbX9CUoUmUHoFnDFY", version: "weekly", mapTypeControl: false
});
export const positions = [{
  "id": "29",
  "title": "ADVapes",
  "description": "",
  "street": "Level 2 Boulevard Place, Heron Crescent",
  "city": "Century City",
  "state": "Western Cape",
  "postal_code": "7441",
  "country": "South Africa",
  "lat": "-33.8883438",
  "lng": "18.5191552",
  "phone": "0825883155",
  "fax": "",
  "email": "info@advapes.co.za",
  "website": "https:\/\/www.advapes.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":[\"09:30 - 18:30\"],\"tue\":[\"09:30 - 18:30\"],\"wed\":[\"09:30 - 18:30\"],\"thu\":[\"09:30 - 18:30\"],\"fri\":[\"09:30 - 18:30\"],\"sat\":[\"09:30 - 18:30\"],\"sun\":[\"09:30 - 18:30\"]}",
  "ordr": "0",
  "slug": "advapes-century-city",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "30",
  "title": "Alpha Warrior Vapes",
  "description": "",
  "street": "13 Reynecke St",
  "city": "Emalahleni",
  "state": "Mpumalanga",
  "postal_code": "1044",
  "country": "South Africa",
  "lat": "-25.8940061",
  "lng": "29.2312644",
  "phone": "0762398723",
  "fax": "",
  "email": "admin@alphawvape.co.za",
  "website": "https:\/\/alphawvape.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":[\"09:30 - 18:30\"],\"tue\":[\"09:30 - 18:30\"],\"wed\":[\"09:30 - 18:30\"],\"thu\":[\"09:30 - 18:30\"],\"fri\":[\"09:30 - 18:30\"],\"sat\":[\"09:30 - 18:30\"],\"sun\":[\"09:30 - 18:30\"]}",
  "ordr": "0",
  "slug": "alpha-warrior-vapes-emalahleni",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "31",
  "title": "Beetlejuice Vape Lounge",
  "description": "",
  "street": "39 Fitzsimons St",
  "city": "Vanderbijlpark",
  "state": "Gauteng",
  "postal_code": "1911",
  "country": "South Africa",
  "lat": "-26.7242563",
  "lng": "27.854358",
  "phone": "0848457290",
  "fax": "",
  "email": "",
  "website": "https:\/\/beetlejuicevape.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "beetlejuice-vape-lounge-vanderbijlpark",
  "brand": "",
  "special": "",
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "117",
  "title": "Bewolkt vape bar",
  "description": "",
  "street": "142 Craib Avenue Riebeeckstad",
  "city": "Welkom",
  "state": "Free State",
  "postal_code": "",
  "country": "South Africa",
  "lat": "-27.9349902",
  "lng": "26.8172328",
  "phone": "0728552173",
  "fax": "",
  "email": "",
  "website": "http:\/\/Bewolktvape.co.za",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "bewolkt-vape-bar-welkom",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "118",
  "title": "Blu Saffron - Pretoria",
  "description": "",
  "street": "Pretoria Country Club",
  "city": "Pretoria",
  "state": "Gauteng",
  "postal_code": "0145",
  "country": "South Africa",
  "lat": "-25.7844325",
  "lng": "28.2528318",
  "phone": "012 346 0223",
  "fax": "",
  "email": "blusaffron@mweb.co.za",
  "website": "https:\/\/www.facebook.com\/blusaffron1\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "blu-saffron-pretoria-pretoria",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "97",
  "title": "Brothers Vaping Lounge Fourways",
  "description": "",
  "street": "Shop G7, Cedar square shopping centre",
  "city": "Fourways",
  "state": "Gauteng",
  "postal_code": "2191",
  "country": "South Africa",
  "lat": "-26.017110168793415",
  "lng": "27.999160655190643",
  "phone": "",
  "fax": "",
  "email": "Brothers4waze@gmail.com",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":[\"09:30 - 18:30\"],\"tue\":[\"09:30 - 18:30\"],\"wed\":[\"09:30 - 18:30\"],\"thu\":[\"09:30 - 18:30\"],\"fri\":[\"09:30 - 18:30\"],\"sat\":[\"09:30 - 18:30\"],\"sun\":[\"09:30 - 18:30\"]}",
  "ordr": "0",
  "slug": "brothers-vaping-lounge-fourways-fourways",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "107",
  "title": "Capital Vapes - Randburg",
  "description": "",
  "street": "Shop 02 Canterbury Crossing 09 Hunter Street Ferndale",
  "city": "Johannesburg",
  "state": "Gauteng",
  "postal_code": "2194",
  "country": "South Africa",
  "lat": "-26.1068744",
  "lng": "28.0011618",
  "phone": "011 326 1278",
  "fax": "",
  "email": "",
  "website": "https:\/\/capitalvapesjhb.com\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "capital-vapes-randburg-johannesburg",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "101",
  "title": "Chops Juice Drops",
  "description": "",
  "street": "35 Hector Avenue",
  "city": "Lotus River",
  "state": "Western Cape",
  "postal_code": "7941",
  "country": "South Africa",
  "lat": "-34.0292275",
  "lng": "18.5210405",
  "phone": "082 615 0595",
  "fax": "",
  "email": "",
  "website": "https:\/\/www.facebook.com\/p\/Chops-Juice-Drops-Express-100074514220127\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "chops-juice-drops-lotus-river",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "32",
  "title": "Cloud Cartel",
  "description": "",
  "street": "1 Bompart St, Westdene",
  "city": "Bloemfontein",
  "state": "Free State",
  "postal_code": "9301",
  "country": "South Africa",
  "lat": "-29.10351409999999",
  "lng": "26.2089408",
  "phone": "0510650429",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "cloud-cartel-bloemfontein",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "34",
  "title": "Cloud Co Vape",
  "description": "",
  "street": "33 Carters road, Kestellhof",
  "city": "Kimberley",
  "state": "Northern Cape",
  "postal_code": "8301",
  "country": "South Africa",
  "lat": "-28.7482934",
  "lng": "24.7423072",
  "phone": "0530011480",
  "fax": "",
  "email": "Sales@CloudCoVape.co.za",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "cloud-co-vape-kimberley",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "96",
  "title": "Cloud house",
  "description": "",
  "street": "96A Old Tanskei Rd",
  "city": "East London",
  "state": "Eastern Cape",
  "postal_code": "",
  "country": "South Africa",
  "lat": "-32.98372010000001",
  "lng": "27.9195361",
  "phone": "0684281144",
  "fax": "",
  "email": "Mariodeatouguia@gmail.com",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":[\"09:30 - 18:30\"],\"tue\":[\"09:30 - 18:30\"],\"wed\":[\"09:30 - 18:30\"],\"thu\":[\"09:30 - 18:30\"],\"fri\":[\"09:30 - 18:30\"],\"sat\":[\"09:30 - 18:30\"],\"sun\":[\"09:30 - 18:30\"]}",
  "ordr": "0",
  "slug": "cloud-house-east-london",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "33",
  "title": "Clouded Vape",
  "description": "",
  "street": "Corner Comaro Street & Soetdoring Ave",
  "city": "Johannesburg",
  "state": "Gauteng",
  "postal_code": "2081",
  "country": "South Africa",
  "lat": "-26.278689063055197",
  "lng": "28.06726679035187",
  "phone": "0826839000",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "clouded-vape-johannesburg",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "35",
  "title": "Cousins Vape",
  "description": "",
  "street": "Corner Bree Street & Oostewal Rd",
  "city": "Langebaan",
  "state": "Western Cape",
  "postal_code": "7357",
  "country": "South Africa",
  "lat": "-33.085314",
  "lng": "18.0363072",
  "phone": "0618824363",
  "fax": "",
  "email": "",
  "website": "https:\/\/cousinsvape.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "cousins-vape-langebaan",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "36",
  "title": "Cumulus Vapours",
  "description": "",
  "street": "59 Shelton Ave, Harmelia",
  "city": "Germiston",
  "state": "Gauteng",
  "postal_code": "1429",
  "country": "South Africa",
  "lat": "-26.1405413",
  "lng": "28.1900759",
  "phone": "0609940328",
  "fax": "",
  "email": "",
  "website": "https:\/\/www.cumulusvapours.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "cumulus-vapours-germiston",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "112",
  "title": "Custom Cloudz Vape (George)",
  "description": "",
  "street": "117 Mitchell Street, Corner Cathedral St, ,",
  "city": "George",
  "state": "Western Cape",
  "postal_code": "6529",
  "country": "South Africa",
  "lat": "-33.960399",
  "lng": "22.4650166",
  "phone": "+27 60 524 0473",
  "fax": "",
  "email": "hello@customcloudz.co.za",
  "website": "customcloudz.co.za",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":[\"9:30 AM - 6:30 PM\"],\"tue\":[\"9:30 AM - 6:30 PM\"],\"wed\":[\"9:30 AM - 6:30 PM\"],\"thu\":[\"9:30 AM - 6:30 PM\"],\"fri\":[\"9:30 AM - 6:30 PM\"],\"sat\":[\"9:30 AM - 6:30 PM\"],\"sun\":[\"9:30 AM - 6:30 PM\"]}",
  "ordr": "0",
  "slug": "custom-cloudz-vape-george-george",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "86",
  "title": "Die Rooi Vlakvarks - Kathu",
  "description": "",
  "street": "Shop 5, Kathu Village Mall",
  "city": "Kathu",
  "state": "Northern Cape",
  "postal_code": "8446",
  "country": "South Africa",
  "lat": "-27.7003776",
  "lng": "23.033128",
  "phone": "0727952694",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "die-rooi-vlakvarks-kathu-kathu",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "85",
  "title": "Die Rooi Vlakvarks - Kimberley",
  "description": "",
  "street": "Shop 107, Diamond Pavilion Mall",
  "city": "Kimberley",
  "state": "Northern Cape",
  "postal_code": "8301",
  "country": "South Africa",
  "lat": "-28.7659838",
  "lng": "24.7644495",
  "phone": "0727956878",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "die-rooi-vlakvarks-kimberley-kimberley",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "37",
  "title": "Die Rooi Vlakvarks - Upington",
  "description": "",
  "street": "Kalahari Mall, Shop 18",
  "city": "Upington",
  "state": "Northern Cape",
  "postal_code": "8801",
  "country": "South Africa",
  "lat": "-28.4436766371685",
  "lng": "21.256845291540525",
  "phone": "0727950981",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "die-rooi-vlakvarks-upington",
  "brand": "",
  "special": "",
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "87",
  "title": "Die Rooi Vlakvarks - Windhoek Grove",
  "description": "",
  "street": "Shop 251, Grove Mall",
  "city": "Kleine Kuppe",
  "state": "Windhoek",
  "postal_code": "9000",
  "country": "Namibia",
  "lat": "-22.6203855",
  "lng": "17.0934667",
  "phone": "+264 813576118",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "die-rooi-vlakvarks-windhoek-grove-kleine-kuppe",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "88",
  "title": "Die Rooi Vlakvarks - Windhoek Wernhil",
  "description": "",
  "street": "Shop 27A, Wernhil Park",
  "city": "Wernhil",
  "state": "WIndhoek",
  "postal_code": "9000",
  "country": "Namibia",
  "lat": "-22.567193",
  "lng": "17.0808412",
  "phone": "+264813576118",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "die-rooi-vlakvarks-windhoek-wernhil-wernhil",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "105",
  "title": "E-vape-orate",
  "description": "",
  "street": "2 Olienhout St, Akasia, Mokopane",
  "city": "Potgietersrus",
  "state": "Limpopo",
  "postal_code": "0601",
  "country": "South Africa",
  "lat": "-24.1801395",
  "lng": "28.9954567",
  "phone": "073 520 0530",
  "fax": "",
  "email": "info@evapeorate.co.za",
  "website": "https:\/\/www.facebook.com\/EVapeOrate1\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "e-vape-orate-potgietersrus",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "79",
  "title": "Eciggies - Pretoria",
  "description": "",
  "street": "Unit 2, 682 Witdoring Ave, Office Complex, Moreleta Park",
  "city": "Pretoria",
  "state": "Gauteng",
  "postal_code": "0044",
  "country": "South Africa",
  "lat": "-25.820016",
  "lng": "28.288523",
  "phone": "0843092520",
  "fax": "",
  "email": "info@eciggies.co.za",
  "website": "http:\/\/www.eciggies.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "eciggies-pretoria-pretoria",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "38",
  "title": "Empire Vapes Alberton",
  "description": "",
  "street": "Atmore St, Brackenhurst",
  "city": "Alberton",
  "state": "Gauteng",
  "postal_code": "1448",
  "country": "South Africa",
  "lat": "-26.3098207",
  "lng": "28.1087998",
  "phone": "0634122647",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "empire-vapes-alberton-alberton",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "102",
  "title": "Foggas Vape Shop",
  "description": "",
  "street": "44 Belvedere Rd",
  "city": "Rondebosch",
  "state": "Western Cape",
  "postal_code": "7700",
  "country": "South Africa",
  "lat": "-33.9769677",
  "lng": "18.4840003",
  "phone": "073 892 9947",
  "fax": "",
  "email": "melissa.foggas@gmail.com",
  "website": "https:\/\/foggasvapeshop.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "foggas-vape-shop-rondebosch",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "39",
  "title": "Fogging Amazing Vryburg",
  "description": "",
  "street": "45 Church St.",
  "city": "Vryburg",
  "state": "North West",
  "postal_code": "8601",
  "country": "South Africa",
  "lat": "-26.9553978",
  "lng": "24.730388",
  "phone": "0786156546",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "fogging-amazing-vryburg-vryburg",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "116",
  "title": "Grumpy Turtle",
  "description": "",
  "street": "577 Ben Swart st Gezina",
  "city": "Pretoria",
  "state": "Gauteng",
  "postal_code": "",
  "country": "South Africa",
  "lat": "-25.7139437",
  "lng": "28.2066871",
  "phone": "0813827004",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "grumpy-turtle-pretoria",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "93",
  "title": "H2 Vape Boksburg",
  "description": "",
  "street": "Trichardts Rd",
  "city": "Boksburg",
  "state": "Gauteng",
  "postal_code": "1459",
  "country": "South Africa",
  "lat": "-26.17751588093988",
  "lng": "28.25536620635985",
  "phone": "062 137 9639",
  "fax": "",
  "email": "admin@h2vape.co.za",
  "website": "http:\/\/www.H2vape.co.za",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":[\"9:30 AM - 6:30 PM\"],\"tue\":[\"9:30 AM - 6:30 PM\"],\"wed\":[\"9:30 AM - 6:30 PM\"],\"thu\":[\"9:30 AM - 6:30 PM\"],\"fri\":[\"9:30 AM - 6:30 PM\"],\"sat\":[\"9:30 AM - 6:30 PM\"],\"sun\":[\"9:30 AM - 6:30 PM\"]}",
  "ordr": "0",
  "slug": "h2-vape-boksburg-boksburg",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "109",
  "title": "H2Vape",
  "description": "",
  "street": "Shop 11, Key Largo Centre Trichardts Road Bardene",
  "city": "Boksburg",
  "state": "Gauteng",
  "postal_code": "1459",
  "country": "South Africa",
  "lat": "-26.1748131",
  "lng": "28.254415",
  "phone": "062 137 9639",
  "fax": "",
  "email": "ADMIN@H2VAPE.CO.ZA",
  "website": "https:\/\/h2v.co\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "h2vape-boksburg",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "110",
  "title": "House of Hubbly",
  "description": "",
  "street": "6 Esselen street Bult",
  "city": "Potchefstroom",
  "state": "North West",
  "postal_code": "2532",
  "country": "South Africa",
  "lat": "-26.6956879",
  "lng": "27.0952024",
  "phone": "076 8106 359",
  "fax": "",
  "email": "gerardt@houseofhubbly.co.za",
  "website": "https:\/\/www.houseofhubbly.co.za\/home.html",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "house-of-hubbly-potchefstroom",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "40",
  "title": "Infinity Vape",
  "description": "",
  "street": "206 Chatsworth Main Rd",
  "city": "Umhlatuzana",
  "state": "KwaZulu-Natal",
  "postal_code": "4092",
  "country": "South Africa",
  "lat": "-29.9121186",
  "lng": "30.922343",
  "phone": "0718286937",
  "fax": "",
  "email": "",
  "website": "https:\/\/infinityvape.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "infinity-vape-umhlatuzana",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "41",
  "title": "Juicy Joes - Durban Road",
  "description": "",
  "street": "The Bridge, 304 Durban Road Upper, Oakdale",
  "city": "Cape Town",
  "state": "Western Cape",
  "postal_code": "7530",
  "country": "South Africa",
  "lat": "-33.8816117",
  "lng": "18.6368438",
  "phone": "0435553333",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "juicy-joes-durban-road-cape-town",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "42",
  "title": "Juicy Joes - Table View",
  "description": "",
  "street": "64 Blaauwberg Rd, Table View",
  "city": "Cape Town",
  "state": "Western Cape",
  "postal_code": "7439",
  "country": "South Africa",
  "lat": "-33.8242234",
  "lng": "18.485263",
  "phone": "0215650444",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "juicy-joes-table-view-cape-town",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "43",
  "title": "Long Dog Trading",
  "description": "",
  "street": "24 Lira Link, Promenade Building Shop 1C",
  "city": "Richards Bay",
  "state": "KwaZulu-Natal",
  "postal_code": "3900",
  "country": "South Africa",
  "lat": "-28.7537847",
  "lng": "32.0520718",
  "phone": "0781380690",
  "fax": "",
  "email": "",
  "website": "https:\/\/longdogtrading.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "long-dog-trading-richards-bay",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "44",
  "title": "Mac Vape",
  "description": "",
  "street": "23 Ferryvale Shopping Centre, 25 Beverley Rd, Ferryvale",
  "city": "Nigel",
  "state": "Gauteng",
  "postal_code": "1491",
  "country": "South Africa",
  "lat": "-26.4305381",
  "lng": "28.4797129",
  "phone": "0765123142",
  "fax": "",
  "email": "",
  "website": "https:\/\/mac-vape-pty-ltd.business.site\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "mac-vape-nigel",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "95",
  "title": "Mac Vape",
  "description": "",
  "street": "Shop 23, Ferryvale Shopping Centre, 25 Beverly Road, Ferryvale",
  "city": "Nigel",
  "state": "Gauteng",
  "postal_code": "1491",
  "country": "South Africa",
  "lat": "-26.430828723504195",
  "lng": "28.479758497553245",
  "phone": "0731377660",
  "fax": "",
  "email": "macvape1@gmail.com",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "mac-vape-nigel-1",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "84",
  "title": "Magic Clouds Vapoury - Malvern",
  "description": "",
  "street": "Shop 22, 7 Westville Rd, Westville",
  "city": "Durban",
  "state": "KwaZulu-Natal",
  "postal_code": "3629",
  "country": "South Africa",
  "lat": "-29.8363452",
  "lng": "30.9176421",
  "phone": "0699177475",
  "fax": "",
  "email": "sales@magiccloudsvapoury.co.za",
  "website": "https:\/\/www.magiccloudsvapoury.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "magic-clouds-vapoury-malvern-durban",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "83",
  "title": "Magic Clouds Vapoury - Westville",
  "description": "",
  "street": "4 Purity Ln, Malvern, Queensburgh",
  "city": "Durban",
  "state": "KwaZulu-Natal",
  "postal_code": "4055",
  "country": "South Africa",
  "lat": "-29.8802329",
  "lng": "30.9201602",
  "phone": "0671739332",
  "fax": "",
  "email": "sales@magiccloudsvapoury.co.za",
  "website": "https:\/\/www.magiccloudsvapoury.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "magic-clouds-vapoury-westville-durban",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "92",
  "title": "Manta Ray",
  "description": "",
  "street": "Shop 20 Hillhouse Stand Street",
  "city": "Plettenburg Bay",
  "state": "wESTERN cAPE",
  "postal_code": "6600",
  "country": "South Africa",
  "lat": "-34.0564374",
  "lng": "23.3727018",
  "phone": "",
  "fax": "",
  "email": "Hello@mantarayvape.co.za",
  "website": "mantarayvape.co.za",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":[\"09:30 - 18:30\"],\"tue\":[\"09:30 - 18:30\"],\"wed\":[\"09:30 - 18:30\"],\"thu\":[\"09:30 - 18:30\"],\"fri\":[\"09:30 - 18:30\"],\"sat\":[\"09:30 - 18:30\"],\"sun\":[\"09:30 - 18:30\"]}",
  "ordr": "0",
  "slug": "manta-ray-plettenburg-bay",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "45",
  "title": "Ohm My Ecig",
  "description": "",
  "street": "Shop 1, Simunye Centre, 2 Village Rd",
  "city": "Kloof",
  "state": "KwaZulu-Natal",
  "postal_code": "3610",
  "country": "South Africa",
  "lat": "-29.7947608",
  "lng": "30.8425303",
  "phone": "0731494318",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "ohm-my-ecig-kloof",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "46",
  "title": "Pandora\'s Box - Die Ou Karooplaas",
  "description": "",
  "street": "84 Saffier Straat",
  "city": "Orania",
  "state": "Northern Cape",
  "postal_code": "8752",
  "country": "South Africa",
  "lat": "-29.8148922",
  "lng": "24.4096482",
  "phone": "0826640073",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "pandora-s-box-die-ou-karooplaas-orania",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "47",
  "title": "Pandora\'s Box - Lephalale Square",
  "description": "",
  "street": "Shop No. 1D, Lephalale Square, Nelson Mandela Drive",
  "city": "Lephalale",
  "state": "Limpopo",
  "postal_code": "0555",
  "country": "South Africa",
  "lat": "-23.68593431489847",
  "lng": "27.70263847539366",
  "phone": "0826640073",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "pandora-s-box-lephalale-square-lephalale",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "49",
  "title": "Pandora\'s Box - Modimolle",
  "description": "",
  "street": "Shop 12, Modimall",
  "city": "Modimolle",
  "state": "Limpopo",
  "postal_code": "0510",
  "country": "South Africa",
  "lat": "-24.7082348",
  "lng": "28.414777",
  "phone": "0826640073",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "pandora-s-box-modimolle-modimolle",
  "brand": "",
  "special": "",
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "48",
  "title": "Pandora\'s Box - Onverwacht",
  "description": "",
  "street": "Shop L, Sunset Point Plaza, Cnr Joe Slovo and Drift Street",
  "city": "Lephalale",
  "state": "Limpopo",
  "postal_code": "0555",
  "country": "South Africa",
  "lat": "-23.66656907837884",
  "lng": "27.744477130619035",
  "phone": "0826640073",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "pandora-s-box-onverwacht-lephalale",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "57",
  "title": "Pandora\'s Box - Sparrow",
  "description": "",
  "street": "Shop SH10G017B, Sparrow Shopping Centre, Cnr Meiniger and Nelson Mandela Drive",
  "city": "Modimolle",
  "state": "Limpopo",
  "postal_code": "0510",
  "country": "South Africa",
  "lat": "-24.7082348",
  "lng": "28.414777",
  "phone": "0826640073",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "pandora-s-box-sparrow-modimolle",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "50",
  "title": "Rebel Revolution Centurion",
  "description": "",
  "street": "Shop 10, Eldo Square Shopping Centre, Volga St",
  "city": "Centurion",
  "state": "Gauteng",
  "postal_code": "0157",
  "country": "South Africa",
  "lat": "-25.8361276",
  "lng": "28.1397255",
  "phone": "0625144108",
  "fax": "",
  "email": "",
  "website": "https:\/\/www.rebelrevolutionvape.com\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "rebel-revolution-centurion-centurion",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "90",
  "title": "Rebel Revolution Kathu",
  "description": "",
  "street": "Heritage Mall Shop 20, Rooisand Str & Frikkie Meyer Str",
  "city": "Kathu",
  "state": "Northern Cape",
  "postal_code": "8446",
  "country": "South Africa",
  "lat": "-27.691544900442786",
  "lng": "23.068949175918586",
  "phone": "0652824698",
  "fax": "",
  "email": "kobusv@revolutionvape.co.za",
  "website": "http:\/\/www.rebelrevolutionvape.co.za",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":[\"9:30 AM - 6:30 PM\"],\"tue\":[\"9:30 AM - 6:30 PM\"],\"wed\":[\"9:30 AM - 6:30 PM\"],\"thu\":[\"9:30 AM - 6:30 PM\"],\"fri\":[\"9:30 AM - 6:30 PM\"],\"sat\":[\"9:30 AM - 6:30 PM\"],\"sun\":[\"9:30 AM - 6:30 PM\"]}",
  "ordr": "0",
  "slug": "rebel-revolution-kathu-kathu",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "89",
  "title": "Rebel Revolution Upington",
  "description": "",
  "street": "Kgalagadi PNP Centre Shop 18, Le Roux Str",
  "city": "Upington",
  "state": "Northern Cape",
  "postal_code": "8801",
  "country": "South Africa",
  "lat": "-28.4606459",
  "lng": "21.2409537",
  "phone": "0840194999",
  "fax": "",
  "email": "kobusv@revolutionvape.co.za",
  "website": "http:\/\/www.rebelrevolutionvape.co.za",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":[\"09:30 - 18:30\"],\"tue\":[\"09:30 - 18:30\"],\"wed\":[\"09:30 - 18:30\"],\"thu\":[\"09:30 - 18:30\"],\"fri\":[\"09:30 - 18:30\"],\"sat\":[\"09:30 - 18:30\"],\"sun\":[\"09:30 - 18:30\"]}",
  "ordr": "0",
  "slug": "rebel-revolution-upington-upington",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "108",
  "title": "Rebel revolution \u2013 Upington",
  "description": "",
  "street": "19 Coetzee Street",
  "city": "Upington",
  "state": "Northern Cape",
  "postal_code": "8800",
  "country": "South Africa",
  "lat": "-28.4502889",
  "lng": "21.2544258",
  "phone": "084 019 4999",
  "fax": "",
  "email": "kobusv@revolutionvape.co.za",
  "website": "https:\/\/www.rebelrevolutionvape.com\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "rebel-revolution-upington-upington-1",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "51",
  "title": "Richie\'s Vapes",
  "description": "",
  "street": "55 Main St, Elti Villas",
  "city": "Louis Trichardt",
  "state": "Limpopo",
  "postal_code": "0920",
  "country": "South Africa",
  "lat": "-23.0510357",
  "lng": "29.9195261",
  "phone": "0837865444",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "richie-s-vapes-louis-trichardt",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "52",
  "title": "Rustic Tobacco",
  "description": "",
  "street": "Crn of long road and, Toronto Rd, Welkom Central",
  "city": "Welkom",
  "state": "Free State",
  "postal_code": "9459",
  "country": "South Africa",
  "lat": "-27.98729057813871",
  "lng": "26.733898202377308",
  "phone": "0837469009",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "rustic-tobacco-welkom",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "53",
  "title": "Rustic Vape Shop",
  "description": "",
  "street": "Corner of Keiskamma & Oranje Street, Midwater Centre Shop 41",
  "city": "Middelburg",
  "state": "Mpumalanga",
  "postal_code": "1055",
  "country": "South Africa",
  "lat": "-25.78765",
  "lng": "29.4523226",
  "phone": "0722625404",
  "fax": "",
  "email": "",
  "website": "https:\/\/therusticvape.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "rustic-vape-shop-middelburg",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "54",
  "title": "Severus Vape",
  "description": "",
  "street": "17 Gleneagles Rd, Greenside",
  "city": "Randburg",
  "state": "Gauteng",
  "postal_code": "2193",
  "country": "South Africa",
  "lat": "-26.1460063",
  "lng": "28.009444",
  "phone": "0674116936",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "severus-vape-randburg",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "55",
  "title": "Shipwreck Vapes Centurion",
  "description": "",
  "street": "295 Galway Ave, Bronberrick",
  "city": "Centurion",
  "state": "Gauteng",
  "postal_code": "0157",
  "country": "South Africa",
  "lat": "-25.859328",
  "lng": "28.167203",
  "phone": "0662706986",
  "fax": "",
  "email": "",
  "website": "https:\/\/www.shipwreckvapes.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "shipwreck-vapes-centurion-centurion",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "56",
  "title": "Shipwreck Vapes Montana",
  "description": "",
  "street": "Shop 24, 390 Braam Pretorius St, Magalieskruin",
  "city": "Pretoria",
  "state": "Gauteng",
  "postal_code": "0182",
  "country": "South Africa",
  "lat": "-25.6869578",
  "lng": "28.2314473",
  "phone": "0790855462",
  "fax": "",
  "email": "",
  "website": "https:\/\/www.shipwreckvapes.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "shipwreck-vapes-montana-pretoria",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "58",
  "title": "Smokers Dynasty East London",
  "description": "",
  "street": "50 Frere Rd, Vincent",
  "city": "East London",
  "state": "Eastern Cape",
  "postal_code": "5217",
  "country": "South Africa",
  "lat": "-32.983404",
  "lng": "27.9018828",
  "phone": "0676159417",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "smokers-dynasty-east-london-east-london",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "59",
  "title": "Smokers Joint",
  "description": "",
  "street": "Lemon Reef Park, 12, 12 Douglas Crowe Dr, Dolphin Coast",
  "city": "Ballito",
  "state": "KwaZulu-Natal",
  "postal_code": "4301",
  "country": "South Africa",
  "lat": "-29.5289662",
  "lng": "31.199134",
  "phone": "0837629392",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "smokers-joint-ballito",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "60",
  "title": "Smokey Joes Vryburg",
  "description": "",
  "street": "2b Fincham St",
  "city": "Vryburg",
  "state": "North West",
  "postal_code": "8600",
  "country": "South Africa",
  "lat": "-26.9508067",
  "lng": "24.7229071",
  "phone": "0539273983",
  "fax": "",
  "email": "",
  "website": "https:\/\/smokeyjoes.store\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "smokey-joes-vryburg-vryburg",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "61",
  "title": "South Cape Vapes George",
  "description": "",
  "street": "46 Market St, George Central",
  "city": "George",
  "state": "Western Cape",
  "postal_code": "6530",
  "country": "South Africa",
  "lat": "-33.962458",
  "lng": "22.4595767",
  "phone": "0630272311",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "south-cape-vapes-george-george",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "62",
  "title": "South Cape Vapes Mossel Bay",
  "description": "",
  "street": "6 Mascador St, Voorbaai",
  "city": "Mossel Bay",
  "state": "Western Cape",
  "postal_code": "6500",
  "country": "South Africa",
  "lat": "-34.1510975",
  "lng": "22.1006159",
  "phone": "0814475063",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "south-cape-vapes-mossel-bay-mossel-bay",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "91",
  "title": "That Vape Shop",
  "description": "",
  "street": "Shop18c, 18 Aberdeen Street",
  "city": "Hermanus",
  "state": "Western Cape",
  "postal_code": "7200",
  "country": "South Africa",
  "lat": "-34.4187107",
  "lng": "19.2408475",
  "phone": "0659895827",
  "fax": "",
  "email": "accounts@thatvapeshop.co.za",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":[\"09:30 - 18:30\"],\"tue\":[\"09:30 - 18:30\"],\"wed\":[\"09:30 - 18:30\"],\"thu\":[\"09:30 - 18:30\"],\"fri\":[\"09:30 - 18:30\"],\"sat\":[\"09:30 - 18:30\"],\"sun\":[\"09:30 - 18:30\"]}",
  "ordr": "0",
  "slug": "that-vape-shop-hermanus",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "119",
  "title": "The Outpost - Dullstroom",
  "description": "",
  "street": "F4GG+P2",
  "city": "Dullstroom",
  "state": "",
  "postal_code": "",
  "country": "South Africa",
  "lat": "-25.5231875",
  "lng": "30.1250625",
  "phone": "082 876 5629",
  "fax": "",
  "email": "bookings@theoutpostdullstroom.co.za",
  "website": "https:\/\/www.theoutpostdullstroom.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "the-outpost-dullstroom-dullstroom",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "65",
  "title": "The Vape Marshall",
  "description": "",
  "street": "409 Stateway road, Doorn",
  "city": "Welkom",
  "state": "Free State",
  "postal_code": "9459",
  "country": "South Africa",
  "lat": "-27.98544",
  "lng": "26.75266",
  "phone": "0823356404",
  "fax": "",
  "email": "clintboyd46@gmail.com",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "the-vape-marshall-welkom",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "98",
  "title": "The Vape Studio",
  "description": "",
  "street": "Shop 14, Royal Palms Court, Corner Loch str & Pierneef blvd",
  "city": "Meyerton",
  "state": "Gauteng",
  "postal_code": "1961",
  "country": "South Africa",
  "lat": "-26.5610172",
  "lng": "28.0222701",
  "phone": "0640236073",
  "fax": "",
  "email": "info@thevapestudio.co.za",
  "website": "https:\/\/thevapestudio.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "the-vape-studio-meyerton",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "99",
  "title": "The Vape Studio Express",
  "description": "",
  "street": "Cnr Meyer Street and, Boet Kruger St",
  "city": "Meyerton",
  "state": "",
  "postal_code": "1961",
  "country": "South Africa",
  "lat": "-26.55505550474847",
  "lng": "28.011617795770274",
  "phone": "0640236073",
  "fax": "",
  "email": "",
  "website": "https:\/\/tvs-express.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":[\"09:30 - 18:30\"],\"tue\":[\"09:30 - 18:30\"],\"wed\":[\"09:30 - 18:30\"],\"thu\":[\"09:30 - 18:30\"],\"fri\":[\"09:30 - 18:30\"],\"sat\":[\"09:30 - 18:30\"],\"sun\":[\"09:30 - 18:30\"]}",
  "ordr": "0",
  "slug": "the-vape-studio-express-meyerton",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "64",
  "title": "The Vaping Bull",
  "description": "",
  "street": "Oudewerf Centre, corner of Landros and Muller Street",
  "city": "Bethlehem",
  "state": "Free State",
  "postal_code": "9701",
  "country": "South Africa",
  "lat": "-28.2325299",
  "lng": "28.3075998",
  "phone": "0660290908",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "the-vaping-bull-bethlehem",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "63",
  "title": "Tobacco City Klerksdorp",
  "description": "",
  "street": "66 Central Ave, Flamwood",
  "city": "Klerksdorp",
  "state": "North West",
  "postal_code": "2571",
  "country": "South Africa",
  "lat": "-26.8476581",
  "lng": "26.6741422",
  "phone": "0184620446",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "tobacco-city-klerksdorp-klerksdorp",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "100",
  "title": "Total Vape",
  "description": "",
  "street": "19 Fettes Road, North End",
  "city": "Port Elizabeth",
  "state": "",
  "postal_code": "6001",
  "country": "South Africa",
  "lat": "-33.9416534",
  "lng": "25.600656",
  "phone": "073 303 9845",
  "fax": "",
  "email": "info@totalvape.co.za",
  "website": "https:\/\/totalvape.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "total-vape-port-elizabeth",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "81",
  "title": "Vairo Vapes Brits",
  "description": "",
  "street": "Shop 43, Magalies Hills Shopping Centre",
  "city": "Brits",
  "state": "Gauteng",
  "postal_code": "0250",
  "country": "South Africa",
  "lat": "-25.6290217",
  "lng": "27.7778251",
  "phone": "0679410744",
  "fax": "",
  "email": "VairoVapes@gmail.com",
  "website": "https:\/\/vairo-vapes.myshopify.com\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "vairo-vapes-brits-brits",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "82",
  "title": "Vairo Vapes Pretoria North",
  "description": "",
  "street": "Shop 5, Florauna Sentrum 586 Corner Brits &, West St, Pretoria North",
  "city": "Pretoria",
  "state": "Gauteng",
  "postal_code": "0182",
  "country": "South Africa",
  "lat": "-25.67967059252994",
  "lng": "28.166255837434385",
  "phone": "0812092139",
  "fax": "",
  "email": "VairoVapes@gmail.com",
  "website": "https:\/\/vairo-vapes.myshopify.com\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "vairo-vapes-pretoria-north-pretoria",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "66",
  "title": "Vanilla Vapes",
  "description": "",
  "street": "21 OR Tambo Rd, Witbank",
  "city": "Emalahleni",
  "state": "Mpumalanga",
  "postal_code": "1035",
  "country": "South Africa",
  "lat": "-25.9318822",
  "lng": "29.2239711",
  "phone": "0645222305",
  "fax": "",
  "email": "vanillavape01@gmail.com",
  "website": "https:\/\/vanillavape.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "vanilla-vapes-emalahleni",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "111",
  "title": "Vape 24",
  "description": "",
  "street": "56 Barry Hertzog Drive",
  "city": "Roodepoort",
  "state": "Gauteng",
  "postal_code": "1709",
  "country": "South Africa",
  "lat": "-26.1591831",
  "lng": "27.9163051",
  "phone": "064 900 2215",
  "fax": "",
  "email": "",
  "website": "https:\/\/www.facebook.com\/Thevape24",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":[\"09:30 - 18:30\"],\"tue\":[\"09:30 - 18:30\"],\"wed\":[\"09:30 - 18:30\"],\"thu\":[\"09:30 - 18:30\"],\"fri\":[\"09:30 - 18:30\"],\"sat\":[\"09:30 - 18:30\"],\"sun\":[\"09:30 - 18:30\"]}",
  "ordr": "0",
  "slug": "vape-24-roodepoort",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "67",
  "title": "Vape Bawse",
  "description": "",
  "street": "9 Berthwell Drive",
  "city": "Stanger",
  "state": "KwaZulu-Natal",
  "postal_code": "4450",
  "country": "South Africa",
  "lat": "-29.326535",
  "lng": "31.2884546",
  "phone": "0730898786",
  "fax": "",
  "email": "info@vapebawse.co.za",
  "website": "https:\/\/vapebawse-vaporizerstore.business.site\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "vape-bawse-stanger",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "114",
  "title": "Vape Cartel Cape Town",
  "description": "",
  "street": "Shop 15 E 160 Main Rd",
  "city": "Plumstead",
  "state": "Cape Town",
  "postal_code": "",
  "country": "South Africa",
  "lat": "-34.0212154",
  "lng": "18.4671785",
  "phone": "0217614715",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "vape-cartel-cape-town-plumstead",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "113",
  "title": "Vape Cartel Meyersdal",
  "description": "",
  "street": "1 Seef Le roux st Meyersdal",
  "city": "Alberton",
  "state": "Gauteng",
  "postal_code": "",
  "country": "South Africa",
  "lat": "-26.29065885696934",
  "lng": "28.091873721565232",
  "phone": "0118671423",
  "fax": "",
  "email": "Info@vapecartel.co.za",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":[\"09:30 - 18:30\"],\"tue\":[\"09:30 - 18:30\"],\"wed\":[\"09:30 - 18:30\"],\"thu\":[\"09:30 - 18:30\"],\"fri\":[\"09:30 - 18:30\"],\"sat\":[\"09:30 - 18:30\"],\"sun\":[\"09:30 - 18:30\"]}",
  "ordr": "0",
  "slug": "vape-cartel-meyersdal-alberton",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "69",
  "title": "Vape Co Express",
  "description": "",
  "street": "Shop 4b, Brackenhurst Square, 17 Abel Moller St, Brackenhurst",
  "city": "Alberton",
  "state": "Gauteng",
  "postal_code": "1448",
  "country": "South Africa",
  "lat": "-26.30948451921484",
  "lng": "28.10753100800401",
  "phone": "0871509023",
  "fax": "",
  "email": "",
  "website": "https:\/\/vapeco.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "vape-co-express-alberton",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "72",
  "title": "Vape Dragon",
  "description": "",
  "street": "287 Walter Sisulu Ln",
  "city": "Potchefstroom",
  "state": "North West",
  "postal_code": "2531",
  "country": "South Africa",
  "lat": "-26.724806525880226",
  "lng": "27.096301265081774",
  "phone": "0738397383",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "vape-dragon-potchefstroom",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "94",
  "title": "Vape Dragon Squad Ladysmith",
  "description": "",
  "street": "Harrismit Road",
  "city": "Ladysmith",
  "state": "",
  "postal_code": "",
  "country": "South Africa",
  "lat": "-28.5568153563549",
  "lng": "29.77205506594544",
  "phone": "",
  "fax": "",
  "email": "ladysmith@vapedragonsquad.co.za",
  "website": "www.vapedragonsquad_ladysmith.co.za",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":[\"9:30 AM - 6:30 PM\"],\"tue\":[\"9:30 AM - 6:30 PM\"],\"wed\":[\"9:30 AM - 6:30 PM\"],\"thu\":[\"9:30 AM - 6:30 PM\"],\"fri\":[\"9:30 AM - 6:30 PM\"],\"sat\":[\"9:30 AM - 6:30 PM\"],\"sun\":[\"9:30 AM - 6:30 PM\"]}",
  "ordr": "0",
  "slug": "vape-dragon-squad-ladysmith-ladysmith",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "73",
  "title": "Vape Faculty",
  "description": "",
  "street": "81 Rose Ave",
  "city": "Lenasia",
  "state": "Gauteng",
  "postal_code": "1822",
  "country": "South Africa",
  "lat": "-26.3215553",
  "lng": "27.8315509",
  "phone": "0731039488",
  "fax": "",
  "email": "",
  "website": "https:\/\/www.vapefaculty.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "vape-faculty-lenasia",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "74",
  "title": "Vape Odyssey",
  "description": "",
  "street": "Horizon Shopping Centre Cnr. Sonop Street, Ontdekkers Rd, Horizon View.",
  "city": "Roodepoort",
  "state": "Gauteng",
  "postal_code": "1724",
  "country": "South Africa",
  "lat": "-26.142911389309344",
  "lng": "27.868940093115228",
  "phone": "0648732852",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "vape-odyssey-roodepoort",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "75",
  "title": "Vape Queen",
  "description": "",
  "street": "Sunward Centre Cnr Duiker &, Kingfisher Ave, Sunward Park",
  "city": "Boksburg",
  "state": "Gauteng",
  "postal_code": "1459",
  "country": "South Africa",
  "lat": "-26.260244964529587",
  "lng": "28.254266412442032",
  "phone": "0782163132",
  "fax": "",
  "email": "",
  "website": "https:\/\/www.vpqueen.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "vape-queen-boksburg",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "103",
  "title": "Vape Zone \u2013 Ferndale",
  "description": "",
  "street": "Shop L4B, Ferndale on Republic, Republic road",
  "city": "Randburg",
  "state": "Gauteng",
  "postal_code": "2125",
  "country": "South Africa",
  "lat": "-26.1000515",
  "lng": "28.0009409",
  "phone": "",
  "fax": "",
  "email": "",
  "website": "https:\/\/www.facebook.com\/VapeZoneFerndale\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "vape-zone-ferndale-randburg",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "70",
  "title": "Vapers Corner Express Atterbury",
  "description": "",
  "street": "2001 Atterbury Rd, Faerie Glen",
  "city": "Pretoria",
  "state": "Gauteng",
  "postal_code": "0043",
  "country": "South Africa",
  "lat": "-25.78816221245587",
  "lng": "28.306753032016008",
  "phone": "0101101060",
  "fax": "",
  "email": "atterbury@vaperscorner.co.za",
  "website": "https:\/\/vaperscorner.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "vapers-corner-express-atterbury-pretoria",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "71",
  "title": "Vapers Corner Kyalami",
  "description": "",
  "street": "113 Begonia Rd, Kyalami",
  "city": "Midrand",
  "state": "Gauteng",
  "postal_code": "1684",
  "country": "South Africa",
  "lat": "-25.98707706983108",
  "lng": "28.072786665359505",
  "phone": "0101101060",
  "fax": "",
  "email": "kyalami@vaperscorner.co.za",
  "website": "https:\/\/vaperscorner.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "vapers-corner-kyalami-midrand",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "106",
  "title": "Vapers Corner \u2013 Equestria",
  "description": "",
  "street": "Shop 14, Equestria Shopping Centre, 12 Furrow Rd, Equestria",
  "city": "Pretoria",
  "state": "Gauteng",
  "postal_code": "0083",
  "country": "South Africa",
  "lat": "-25.754124383994867",
  "lng": "28.325853894442744",
  "phone": "010 110 1060",
  "fax": "",
  "email": "equestria@vaperscorner.co.za",
  "website": "https:\/\/vaperscorner.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "vapers-corner-equestria-pretoria",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "80",
  "title": "Vapers lab (JHB)",
  "description": "",
  "street": "Linden Square, corner 4th avenue and, 8th St, Linden",
  "city": "Ranburg",
  "state": "Gauteng",
  "postal_code": "2104",
  "country": "South Africa",
  "lat": "-26.141455852050655",
  "lng": "27.993629224606313",
  "phone": "0713150207",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "vapers-lab-jhb-ranburg",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "115",
  "title": "Vaping Brothers Rustenburg",
  "description": "",
  "street": "Shop 13 1 Faisal Mafa Dr Waterfall park",
  "city": "Rustenburg",
  "state": "North West",
  "postal_code": "",
  "country": "South Africa",
  "lat": "-25.7023638",
  "lng": "27.2531456",
  "phone": "0692860056",
  "fax": "",
  "email": "",
  "website": "",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "vaping-brothers-rustenburg-rustenburg",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "104",
  "title": "Vaporhood",
  "description": "",
  "street": "181 Panorama Rd Centurion",
  "city": "Centurion",
  "state": "Gauteng",
  "postal_code": "0154",
  "country": "South Africa",
  "lat": "-25.8932813",
  "lng": "28.140262",
  "phone": "079 150 4589",
  "fax": "",
  "email": "info@vaporhood.co.za",
  "website": "https:\/\/vaporhood.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "vaporhood-centurion",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "76",
  "title": "Vaporize",
  "description": "",
  "street": "402 Theuns van Niekerk street Wierda Shopping Centre 2, Wierdapark",
  "city": "Centurion",
  "state": "Gauteng",
  "postal_code": "0157",
  "country": "South Africa",
  "lat": "-25.8696686",
  "lng": "28.1515825",
  "phone": "0120301035",
  "fax": "",
  "email": "info@vaporiz-vapelounge.com",
  "website": "https:\/\/www.vaporiz-vapelounge.com\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "vaporize-centurion",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "77",
  "title": "Wick and Juice",
  "description": "",
  "street": "4 Willet Crescent, Zeekoevlei",
  "city": "Cape Town",
  "state": "Western Cape",
  "postal_code": "7941",
  "country": "South Africa",
  "lat": "-34.0546915",
  "lng": "18.5011224",
  "phone": "0614990909",
  "fax": "",
  "email": "wickandjuice@gmail.com",
  "website": "https:\/\/wickandjuice.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "wick-and-juice-cape-town",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}, {
  "id": "78",
  "title": "Wiener Vape Co.",
  "description": "",
  "street": "24 Lira Link Promenade, CBD",
  "city": "Richards Bay",
  "state": "KwaZulu-Natal",
  "postal_code": "3900",
  "country": "South Africa",
  "lat": "-28.7537847",
  "lng": "32.0520718",
  "phone": "0630724276",
  "fax": "",
  "email": "info@wienervape.co.za",
  "website": "https:\/\/www.wienervape.co.za\/",
  "logo_id": "0",
  "path": null,
  "marker_id": "1",
  "description_2": "",
  "open_hours": "{\"mon\":\"1\",\"tue\":\"1\",\"wed\":\"1\",\"thu\":\"1\",\"fri\":\"1\",\"sat\":\"1\",\"sun\":\"1\"}",
  "ordr": "0",
  "slug": "wiener-vape-co-richards-bay",
  "brand": null,
  "special": null,
  "categories": null,
  "days_str": "Mon, Tues, Wed, Thur, Fri, Sat, Sun"
}]

export default googleMapLoader

/*
loader.load().then(async () => {
  const { Map } = await google.maps.importLibrary("maps");

  map = new Map(document.getElementById("map"), {
    center: { lat: -34.397, lng: 150.644 },
    zoom: 8,
  });
});

*/