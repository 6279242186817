import Vue from 'vue'
import Vuex from 'vuex'
import auth from "@/api/auth";
import {TOKEN} from "@/config/http-json";
import router from "@/router";
import {Toast} from "vant";


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    selectAddress:
      JSON.parse(window.localStorage.getItem("selectAddress")) || null,
    submitNumber: 1,
    productInfo: null,
    modelCode: null,
    userInfo: (()=> {
      const userinfoStr = localStorage.getItem('userinfo')
      if (userinfoStr) {
        return JSON.parse(userinfoStr)
      }
      return null
    })()

  },
  mutations: {
    setUserInfo(state, payload) {
      delete payload.token
      localStorage.setItem("userinfo", JSON.stringify(payload))
      state.userInfo = payload
    }
  },
  actions: {
    //#region 授权令牌无效，请重新登录
    async tokenExpired({commit}) {
      //清空store
      //清空缓存
      localStorage.clear();
      router.replace({
        name: "Login" //返回首页
      });
    },
    async login({commit}, params) {
      try {
        const data = await auth.login(params);
        localStorage.setItem(TOKEN, data.token)
        commit('setUserInfo', data)
        router.replace('/')
      }catch (e) {
        Toast.fail({
          duration           : 2000,
          message            : e.message,
          closeOnClick       : false,
          closeOnClickOverlay: false
        });
      }
    }
    //#endregion
  },
  getters: {
    getUserName(state,commit) {
      if (state.userInfo == null) {
        return ''
      }
      return state.userInfo.username;
    }
  },
  modules: {}
});
