/*
 * @Author: Code-HHX
 * @Date: 2022-03-24 10:35:21
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-03-24 14:32:21
 * @Description: api 统一出口
 */

import {requestGet, requestGetForm, requestPost, requestPostForm} from "../config/http-json"

const auth = {
  async login(params) {
    const resp = await requestPostForm('/supplier/brandShop/doLogin', params);
    return resp.data
  },
};

export default auth;
